import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeAuth, User } from './types.auth';

const initAuth: TypeAuth = {
  isLogin: false,
  user: null,
  loading: false,
  error: '',
  isLoadingPage: true,
};

const AuthSlice = createSlice({
  name: 'Auth',
  initialState: initAuth,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => ({
      ...state,
      user: action.payload,
      loading: false,
    }),
    setError: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    startFetching: (state) => ({
      ...state,
      loading: true,
      error: '',
    }),
    successFetching: (state) => ({
      ...state,
      loading: false,
    }),
    removeUser: (state) => ({
      ...state,
      user: null,
    }),
    setLoadingPage: (state, action) => ({
      ...state,
      isLoadingPage: action.payload,
    }),
  },
});

export const authActions = AuthSlice.actions;
export const { setUser, setError, startFetching, removeUser, setLoadingPage } = authActions;
export default AuthSlice.reducer;
