import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { usersAPI } from './users';
import { chartsAPI } from './charts';
import { itemsAPI } from './items';
import { ipOwnerAPI } from './ipOwner';
import { franchisesAPI } from './franchises';
import { сollectionAPI } from './сollections';
import { bannersAPI } from './banners';
import { adminsAPI } from './admins';
import { auctionsAPI } from './auction';
import { mysteryBoxAPI } from './mysteryBox';
import { couponAPI } from './coupon';
import { serviceHealthAPI } from './serviceHealth';

import { rootReducer } from './rootReducer';

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      usersAPI.middleware,
      chartsAPI.middleware,
      itemsAPI.middleware,
      ipOwnerAPI.middleware,
      franchisesAPI.middleware,
      сollectionAPI.middleware,
      bannersAPI.middleware,
      adminsAPI.middleware,
      auctionsAPI.middleware,
      mysteryBoxAPI.middleware,
      couponAPI.middleware,
      serviceHealthAPI.middleware,
    ],
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
