import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { fingerprint } from '../helpers/fingerprint';
import { authAsync, setError } from '../redux';
import { useRedux } from './reduxHook';

export const useAuth = () => {
  const { dispatch } = useRedux();
  const fingerprintId = fingerprint();

  const authEmail = async ({ login, password }: { [key: string]: string }) => {
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, login, password);
      const currentUser = await auth.currentUser?.getIdTokenResult();

      if (!currentUser) return;

      dispatch(
        authAsync({
          idToken: currentUser.token,
          fingerprint: fingerprintId,
        }),
      );
    } catch (error: any) {
      dispatch(setError(error?.code));
    }
  };

  const authGoogle = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');

    try {
      await signInWithPopup(auth, provider);
      const currentUser: any = await auth.currentUser;
      const currentUserToken = await currentUser?.getIdTokenResult();

      dispatch(
        authAsync({
          idToken: currentUserToken.token,
          fingerprint: fingerprintId,
        }),
      );
    } catch (error: any) {
      dispatch(setError(error.code));
    }
  };

  return { authGoogle, authEmail };
};
