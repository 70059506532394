import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { apiAuth, baseUrl, URLS } from '../api';
import { login, logout } from '../redux';
import { fingerprint } from './fingerprint';

export const fetchBaseQueryAuth = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token') || '';

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await fetchBaseQueryAuth(args, api, extraOptions);
  const fingerprintId = fingerprint();

  const refreshToken = localStorage.getItem('refresh_token') || '';
  if (result.error && result.error.status === 401) {
    const { data }: any = await fetchBaseQueryAuth(
      {
        url: URLS.refreshTokens,
        method: 'POST',
        body: { refresh_token: refreshToken, fingerprint: fingerprintId },
      },
      api,
      extraOptions,
    );

    if (data) {
      // store the new token
      api.dispatch(login(data.user, data.accessToken));
      // retry the initial query
      result = await fetchBaseQueryAuth(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }
  return result;
};

type AxiosBaseQueryProps = {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  headers?: AxiosRequestConfig['headers'];
  timeout?: AxiosRequestConfig['timeout'];
};

export const axiosBaseQuery = async ({
  url,
  method,
  data,
  headers,
  timeout,
}: AxiosBaseQueryProps) => {
  try {
    const obj: { [key: string]: any } = {
      get: apiAuth.get,
      post: apiAuth.post,
      put: apiAuth.put,
      delete: apiAuth.delete,
    };

    const meth = method || 'get';

    const result = await obj[meth](url, data, { headers, timeout });
    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};
