import { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import moment from 'moment';
import { setingsNotification } from '../constants';
import { IconError, IconCheckSquare } from '../assets/icons';
import { BurnPayloadType } from '../redux';

type UseActionItemProps = {
  deleteMutation?: any;
  editMutation?: any;
  burnMutation?: any;
  clearState?: () => void;
  setFetchAll?: (e: boolean) => void;
  mysteryBoxType?: string;
};

export const useActionItem = ({
  deleteMutation,
  editMutation,
  burnMutation,
  clearState = () => {},
  setFetchAll = () => {},
  mysteryBoxType,
}: UseActionItemProps) => {
  const { push } = useHistory();
  const [selectItem, setSelectItem] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [openBurnModal, setOpenBurnModal] = useState(false);

  const handleTrashItem = (item: any) => {
    setSelectItem(item);
    setOpenModal(true);
  };

  const handleEditItem = (item: any) => {
    setSelectItem(item);
  };

  const handleOk = async (rout?: string, id?: number) => {
    await deleteMutation({ id: selectItem?.id || id })
      .unwrap()
      .then(() => {
        toast.success('Deleting successful!', {
          ...setingsNotification,
          icon: <IconCheckSquare />,
        });
        if (rout) {
          push(rout);
        }
      })
      .catch(() => toast.error('Deleting error!', { ...setingsNotification, icon: <IconError /> }));
    setSelectItem(undefined);
    setOpenModal(false);
    clearState();
  };

  const handleVisible = async (visible: boolean, id: string | string) => {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ is_visible: visible }));
    await editMutation({ formData, id, ...(mysteryBoxType && { mysteryBoxType }) })
      .unwrap()
      .then(() => {
        toast.success(`Visible ${visible ? 'on' : 'off'} successful!`, {
          ...setingsNotification,
          icon: <IconError />,
        });
      })
      .catch(() =>
        toast.error('Visible error!', { ...setingsNotification, icon: <IconCheckSquare /> }),
      );
    setFetchAll(true);
  };

  const handleFeatured = async (featured: boolean, id: string | string) => {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ featured }));
    await editMutation({ formData, id, ...(mysteryBoxType && { mysteryBoxType }) })
      .unwrap()
      .then(() => {
        toast.success(`Featured ${featured ? 'on' : 'off'} successful!`, {
          ...setingsNotification,
          icon: <IconError />,
        });
      })
      .catch(() =>
        toast.error('Featured error!', { ...setingsNotification, icon: <IconCheckSquare /> }),
      );
    setFetchAll(true);
  };

  const handlePublishNow = async (id: string | string) => {
    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({ release_datetime: moment().utc().format('YYYY_MM_DD:HH_mm') }),
    );

    await editMutation({ formData, id })
      .unwrap()
      .then(() => {
        toast.success(`Publish successful`, {
          ...setingsNotification,
          icon: <IconError />,
        });
      })
      .catch(() =>
        toast.error('Publish error!', { ...setingsNotification, icon: <IconCheckSquare /> }),
      );
    clearState();
  };

  const handleTerminateNow = async (id: string | string) => {
    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({ finish_datetime: moment().utc().format('YYYY_MM_DD:HH_mm') }),
    );

    await editMutation({ formData, id })
      .unwrap()
      .then(() => {
        toast.success(`Terminate successful`, {
          ...setingsNotification,
          icon: <IconError />,
        });
      })
      .catch(() =>
        toast.error('Terminate error!', { ...setingsNotification, icon: <IconCheckSquare /> }),
      );
    clearState();
  };

  const handleNevermind = () => {
    setOpenModal(false);
    setOpenBurnModal(false);
    setSelectItem(undefined);
  };

  const handleClear = () => {
    setSelectItem(undefined);
    clearState();
  };

  const handleBurn = async ({ item, value }: { item?: any; value?: BurnPayloadType }) => {
    if (!value) {
      setSelectItem(item);
      setOpenBurnModal(true);
      return;
    }

    await burnMutation({ value, id: item?.id })
      .unwrap()
      .then(() => {
        toast.success(`Burned successful!`, {
          ...setingsNotification,
          icon: <IconError />,
        });
        handleNevermind();
      })
      .catch(() =>
        toast.error('Burned error!', { ...setingsNotification, icon: <IconCheckSquare /> }),
      );
    setFetchAll(true);
  };

  const handleClick = (type: string, item: any) => {
    push(`${type}/${item.id}`, item);
  };

  return {
    handleTrashItem,
    handleEditItem,
    handleOk,
    handleClear,
    handleNevermind,
    handleClick,
    handleVisible,
    handlePublishNow,
    handleFeatured,
    handleTerminateNow,
    handleBurn,
    openBurnModal,
    openModal,
    selectItem,
  };
};
