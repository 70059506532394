import { removeUser, setError, setUser, startFetching, setLoadingPage } from '.';
import { api, URLS } from '../../api';
import { fingerprint } from '../../helpers/fingerprint';
import { AppDispatch } from '../store';
import { Token, User, AuthResponse } from './types.auth';

const token = localStorage.getItem('token');
const userJson = localStorage.getItem('xnl-user');
const userObject = userJson ? JSON.parse(userJson) : null;

export const login = (user: User, accessToken: Token) => (dispatch: AppDispatch) => {
  localStorage.setItem('token', accessToken.access_token);
  localStorage.setItem('refresh_token', accessToken.refresh_token);
  localStorage.setItem('xnl-user', JSON.stringify(user));
  dispatch(setUser({ ...user, isAuth: true }));
};

export const authAsync = (dataForm: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startFetching());
    const { data }: AuthResponse = await api.post(URLS.auth, { ...dataForm });

    dispatch(login(data.user, data.accessToken));
  } catch (error: any) {
    dispatch(setError(error?.response?.data));
  }
};

export const logout = () => async (dispath: AppDispatch) => {
  try {
    const fingerprintId = fingerprint();

    api.post(URLS.logout, {
      refresh_token: localStorage.getItem('refresh_token'),
      fingerprint: fingerprintId,
    });

    localStorage.removeItem('xnl-user');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    dispath(removeUser());
  } catch (error) {
    dispath(setError(error));
  }
};

export const checkTokenAsync = () => async (dispatch: AppDispatch) => {
  if (!token || !userObject) {
    dispatch(logout());
    dispatch(setLoadingPage(false));
    return;
  }

  const fingerprintId = fingerprint();
  const refreshToken = localStorage.getItem('refresh_token') || '';
  try {
    const { data } = await api.post(URLS.refreshTokens, {
      refresh_token: refreshToken,
      fingerprint: fingerprintId,
    });
    dispatch(login(data.user, data.accessToken));
  } catch (error) {
    dispatch(logout());
  } finally {
    dispatch(setLoadingPage(false));
  }
};
