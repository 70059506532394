import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import { messagesAll } from './translations';
import AppRouter from './router';
import { checkTokenAsync, selectLoadingPage } from './redux';
import { useRedux } from './hooks';

const App = () => {
  const { dispatch, select } = useRedux();
  const isLoadingPage = select(selectLoadingPage);

  useEffect(() => {
    dispatch(checkTokenAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (isLoadingPage) return <></>;

  return (
    <IntlProvider locale="en" defaultLocale="en" messages={messagesAll.en}>
      <ToastContainer className="customToastify" />
      <AppRouter />
    </IntlProvider>
  );
};

export default App;
