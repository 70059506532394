import { ValueType } from 'recharts/types/component/DefaultTooltipContent';

export const numberToLocaleString = (num: number | undefined | ValueType) => {
  if (num === 0) return 0;
  if (!num) return '';
  return num.toLocaleString('RU-ru');
};

export const getValueWithSign = (v: number) =>
  v > 0 ? `+${numberToLocaleString(v)}` : numberToLocaleString(v);

export const kFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? `${((Math.sign(num) * Math.abs(num)) / 1000).toFixed()}K`
    : `${Math.sign(num) * Math.abs(num)}`;
};
