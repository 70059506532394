import { createSlice } from '@reduxjs/toolkit';
import { TypeCoupon } from './types.coupon';

const initCoupon: TypeCoupon = {
  loading: false,
  error: '',
  nameCoupon: [],
};

const CouponSlice = createSlice({
  name: 'Coupon',
  initialState: initCoupon,
  reducers: {
    startCouponFetching: (state) => ({
      ...state,
      loading: true,
      error: '',
    }),
    successCouponFetching: (state) => ({
      ...state,
      loading: false,
      error: '',
    }),
    errorCouponFetching: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    setCouponName: (state, action) => ({
      ...state,
      nameCoupon: action.payload,
    }),
  },
});

export const couponSlice = CouponSlice.actions;
export const { startCouponFetching, successCouponFetching, errorCouponFetching, setCouponName } =
  couponSlice;
export default CouponSlice.reducer;
