export const firebaseConfig = {
  apiKey: 'AIzaSyAC5lVeg-oJuZWZZ3czc35iJ3zgfAj0KZg',
  authDomain: 'nft-chronicle.firebaseapp.com',
  projectId: 'nft-chronicle',
  storageBucket: 'nft-chronicle.appspot.com',
  messagingSenderId: '844694442268',
  appId: '1:844694442268:web:0fe756d121d81609572ad9',
  measurementId: 'G-HMDCBHMJGX',
};

export const devFirebaseConfig = {
      apiKey: 'AIzaSyAC5lVeg-oJuZWZZ3czc35iJ3zgfAj0KZg',
      authDomain: 'nft-chronicle.firebaseapp.com',
      projectId: 'nft-chronicle',
      storageBucket: 'nft-chronicle.appspot.com',
      messagingSenderId: '844694442268',
      appId: '1:844694442268:web:0fe756d121d81609572ad9',
      measurementId: 'G-HMDCBHMJGX',
};
