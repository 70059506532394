import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth/reducers.auth';
import coupon from './coupon/reducers.coupon';
import { usersAPI } from './users';
import { chartsAPI } from './charts';
import { itemsAPI } from './items';
import { ipOwnerAPI } from './ipOwner';
import { franchisesAPI } from './franchises';
import { сollectionAPI } from './сollections';
import { adminsAPI } from './admins';
import { bannersAPI } from './banners';
import { auctionsAPI } from './auction';
import { mysteryBoxAPI } from './mysteryBox';
import { couponAPI } from './coupon';
import { serviceHealthAPI } from './serviceHealth';

export const rootReducer = combineReducers({
  auth,
  coupon,
  [usersAPI.reducerPath]: usersAPI.reducer,
  [chartsAPI.reducerPath]: chartsAPI.reducer,
  [itemsAPI.reducerPath]: itemsAPI.reducer,
  [ipOwnerAPI.reducerPath]: ipOwnerAPI.reducer,
  [franchisesAPI.reducerPath]: franchisesAPI.reducer,
  [сollectionAPI.reducerPath]: сollectionAPI.reducer,
  [adminsAPI.reducerPath]: adminsAPI.reducer,
  [bannersAPI.reducerPath]: bannersAPI.reducer,
  [auctionsAPI.reducerPath]: auctionsAPI.reducer,
  [mysteryBoxAPI.reducerPath]: mysteryBoxAPI.reducer,
  [couponAPI.reducerPath]: couponAPI.reducer,
  [serviceHealthAPI.reducerPath]: serviceHealthAPI.reducer,
});
