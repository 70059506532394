import { ReactChild } from 'react';
import useMetaTags from 'react-metatags-hook';
import { useLocation } from 'react-router-dom';
import { currentRoute } from '../../../helpers/routePosition';
import routes from '../../../router/routes';
import s from '../Layout.module.scss';

type PublicLayoutProps = {
  children: ReactChild;
};

export const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const routesNotAuth = routes.filter((r) => !r.isAuth);
  const currentPage = currentRoute(pathname, routesNotAuth);

  useMetaTags(
    {
      title: currentPage?.name,
      charset: 'utf8',
      lang: 'en',
    },
    [currentPage],
  );

  return (
    <div className={s.layoutWrapper}>
      <div className={s.fullContent}>{children}</div>
    </div>
  );
};
