import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { URLS } from '../../api';
import { axiosBaseQuery } from '../../helpers';
import { CouponsProps, CouponsType } from './types.coupon';

export const couponAPI = createApi({
  reducerPath: 'couponAPI',
  tagTypes: ['Coupon'],
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    createCoupon: build.mutation({
      query: ({ formData }) => ({
        url: URLS.coupons,
        method: 'post',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Coupon'],
    }),
    createRedemptionCoupon: build.mutation({
      query: ({ formData, id }) => ({
        url: `${URLS.coupons}/items/${id}`,
        method: 'post',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Coupon'],
    }),
    editCoupon: build.mutation({
      query: ({ formData, id }) => ({
        url: `${URLS.coupons}/${id}`,
        method: 'put',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Coupon'],
    }),
    deleteCoupon: build.mutation({
      query: ({ id }) => ({
        url: `${URLS.coupons}/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => ['Coupon'],
    }),
    getCoupon: build.query<CouponsType, CouponsProps>({
      query: ({ page, perPage, url, sortObject, currency }) => {
        const sortObjectQuery =
          sortObject?.sortField && sortObject?.sortType
            ? `&${sortObject.sortField}=${sortObject.sortType}`
            : '';

        const currencyQuery = currency && currency !== 'all' ? `&currency=${currency}` : '';

        return {
          url: `${URLS.coupons}?page_number=${page}&page_size=${perPage}${
            url && `&${url}`
          }${sortObjectQuery}${currencyQuery}`,
          method: 'get',
        };
      },
      providesTags: () => ['Coupon'],
    }),
  }),
});

export const {
  useCreateCouponMutation,
  useCreateRedemptionCouponMutation,
  useEditCouponMutation,
  useDeleteCouponMutation,
  useGetCouponQuery,
} = couponAPI;
