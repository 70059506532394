import moment from 'moment';
import { DataType } from '../components';

export const getFullDateFormat = (date: Date | string | undefined | null) =>
  date ? moment(date).format('MMM, DD  YYYY') : '';

export const getRequestDateFormatted = (date: Date) => moment(date).format('YYYY_MM_DD');

export const setTimestampByTab = (arr: DataType[], selectedTab: string) =>
  arr.map((i) => {
    let timestamp = moment(i.timestamp).format('MMM YY');
    if (selectedTab === 'hour') timestamp = moment(i.timestamp).format('HH:mm');
    else if (selectedTab === 'day') timestamp = moment(i.timestamp).format('DD MMM');
    // else if (selectedTab === 'All time') moment(i.timestamp).format('MMM YY');
    else timestamp = moment(i.timestamp).format('MMM YY');

    return {
      ...i,
      timestamp,
    };
  });
