export const URLS: { [key: string]: any } = {
  auth: 'auth',
  roles: 'roles',
  admins: 'admins',
  carousels: 'carousels',
  logout: 'logout',
  refreshTokens: 'auth/refresh_tokens',
  brands: 'brands',
  stores: 'stores',
  collections: 'collections',
  items: 'items',
  mysteryBoxes: 'mystery_boxes',
  salesRevenue: 'sales/get_revenue',
  serviceHealth: 'service_health',

  // dashboard start
  scalars: 'dashboard/scalars',
  royalties: 'dashboard/royalties',
  timeseries: 'dashboard/timeseries',
  usersReg: 'dashboard/registrations/timeseries',
  usersRegCount: 'dashboard/registrations/count',
  efficiency: 'dashboard/efficiency',
  balances: 'dashboard/xnl_balances',
  // dashboard end

  ban: 'ban',
  users: 'users',
  userNfts: 'users/<id>/nfts',
  giveaway: 'items/<id>/giveaway',
  auctions: 'auctions',
  auctionBid: 'auctions/<id>/bids',
  loginBanner: 'login_banners',
  coupons: 'coupons',
  couponsNames: 'coupons/generate_names',
};

export const baseUrl = `${process.env.REACT_APP_API_DOMAIN}/api/v1/`;
