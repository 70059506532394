import App from './App';
import { firebaseConfig, devFirebaseConfig } from './config';
import './index.scss';
import { setupStore } from './redux/store';
import * as firebase from 'firebase/app';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

if(process.env.REACT_APP_ENV === "development") {
    firebase.initializeApp(devFirebaseConfig);
} else {
    firebase.initializeApp(firebaseConfig);
}

export const store = setupStore();

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
