import { priceToNumber } from './price';

const checkTypeValue = (v: any, f: any) => {
  if (v === Object(v) && !Array.isArray(v)) return f(v);
  if (
    typeof v === 'string' &&
    v
      .replace(/\s/g, '')
      .replace('.', '')
      .split('')
      .every((el) => Number.isInteger(+el))
  )
    return priceToNumber(v);

  return v;
};

export const removeEmptyInObject = <T>({ ...obj }: T): { [k: string]: any } =>
  Object.fromEntries(
    Object.entries(obj)
      .filter((item) =>
        typeof item[1] === 'object'
          ? Object.keys(Object(item[1]))?.length
          : item[1] || typeof item[1] === 'number' || typeof item[1] === 'boolean',
      )
      .map(([k, v]) => {
        return [k, checkTypeValue(v, removeEmptyInObject)];
      }),
  );
