/* eslint-disable no-restricted-globals */
import hash from 'object-hash';

export const fingerprint = () => {
  const visitorId = hash({
    userAgent: window.navigator.userAgent,
    language: window.navigator.language,
    timeZone: new Date().getTimezoneOffset(),
    screen: [screen.height, screen.width],
    colorDepth: screen.colorDepth,
  });

  return visitorId;
};
