import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { replaceOldArr } from '../helpers';
import { usePrevious } from './usePrevious';

type useInfinitePaginationProps = {
  query: any;
  perPage?: number;
  url?: string;
};

type useInfinitePaginationReturn<T> = {
  page: number;
  isLoading: boolean;
  isFetching: boolean;
  state: T[];
  hasMore: boolean;
  handleNext: () => void;
  clearState: () => void;
  setFetchAll: (e: boolean) => void;
  refetch: () => void;
};

export const useInfinitePagination = <T extends { [key: string]: any }>(
  { query, perPage = 32, url }: useInfinitePaginationProps,
  queryParam?: {
    skip?: boolean;
  },
): useInfinitePaginationReturn<T> => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [fetchAll, setFetchAll] = useState(false);
  const [state, setstate] = useState<T[]>([]);
  const { location } = useHistory();
  const oldState = usePrevious({ fetchAll, location: location.pathname });

  const { data, isLoading, isFetching, refetch } = query(
    {
      page: fetchAll ? 1 : page,
      perPage: fetchAll ? page * perPage : perPage,
      url,
    },
    queryParam,
  );

  useEffect(() => {
    setstate([]);
    setPage(1);
    setHasMore(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const handleNext = () => {
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (!data || isFetching) return;
    const newData = replaceOldArr<T>({
      oldArr: state,
      newArr: data.data,
      key: 'id',
    });

    if (fetchAll) {
      setFetchAll(false);
      setstate([...newData]);
      return;
    }

    if (oldState?.fetchAll !== fetchAll && location.pathname === oldState?.location) return;

    setstate((prevState) => [...prevState, ...data.data]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching]);

  useEffect(() => {
    setHasMore(data?.total_results > state.length);
  }, [data?.total_results, state]);

  const clearState = () => {
    setstate([]);
  };

  return {
    page,
    isLoading,
    isFetching,
    state,
    hasMore,
    handleNext,
    clearState,
    setFetchAll,
    refetch,
  };
};
