import FileDownload from 'js-file-download';
import { apiAuth, URLS } from '../../api';
import { SortObject } from '../../helpers';
import { AppDispatch } from '../store';
import {
  errorCouponFetching,
  startCouponFetching,
  successCouponFetching,
  setCouponName,
} from './reducers.coupon';

export const getCouponName =
  ({ amount = 1 }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(startCouponFetching());

      const data = await apiAuth.get(`${URLS.couponsNames}?amount=${amount}`);

      dispatch(setCouponName(data.data));

      dispatch(successCouponFetching());
    } catch (error: any) {
      dispatch(errorCouponFetching(error.code));
    }
  };

export const exportCouponsAsync =
  ({ url, sortObject, currency }: { url: string; sortObject?: SortObject; currency?: string }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(startCouponFetching());
      const sortObjectQuery =
        sortObject?.sortField && sortObject?.sortType
          ? `&${sortObject.sortField}=${sortObject.sortType}`
          : '';

      const currencyQuery = currency && currency !== 'all' ? `&currency=${currency}` : '';

      const respons = await apiAuth.get(
        `${URLS.coupons}?export=True${url && `&${url}`}${sortObjectQuery}${currencyQuery}`,
      );

      FileDownload(respons.data, 'coupons.csv');
      dispatch(successCouponFetching());
    } catch (error: any) {
      dispatch(errorCouponFetching(error.code));
    }
  };
