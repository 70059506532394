import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ChartPropsType,
  EfficiencyResponseType,
  RoyaltiesResponseType,
  ScalarsResponseType,
  TimePeriodType,
  TimeseriesResponseType,
  UserRegCountResponseType,
  UserRegPesponseType,
  WalletsResponseType,
} from '.';
import { URLS } from '../../api';
import { ISelectOption } from '../../components/FormItems/CustomSelect/type';
import { axiosBaseQuery } from '../../helpers';

export const chartsAPI = createApi({
  baseQuery: axiosBaseQuery,
  tagTypes: ['Charts'],
  endpoints: (build) => ({
    getScalars: build.query<ScalarsResponseType, ChartPropsType>({
      query: ({ timePeriod, currency }) => ({
        url: `${URLS.scalars}?currency=${currency}${
          timePeriod ? `&date_period=${timePeriod}` : ''
        }`,
        method: 'get',
      }),
    }),
    getRoyalties: build.query<RoyaltiesResponseType, ChartPropsType>({
      query: ({ timePeriod, currency }) => ({
        url: `${URLS.royalties}?currency=${currency}${
          timePeriod ? `&date_period=${timePeriod}` : ''
        }`,
        method: 'get',
      }),
    }),
    getTimeseries: build.query<TimeseriesResponseType, ChartPropsType>({
      query: ({ timePeriod, currency }) => ({
        url: `${URLS.timeseries}?currency=${currency}${
          timePeriod ? `&date_period=${timePeriod}` : ''
        }`,
        method: 'get',
      }),
    }),
    getUserReg: build.query<UserRegPesponseType, TimePeriodType>({
      query: ({ timePeriod }) => ({
        url: `${URLS.usersReg}${timePeriod ? `?date_period=${timePeriod}` : ''}`,
        method: 'get',
      }),
    }),
    getUserRegCount: build.query<UserRegCountResponseType, TimePeriodType>({
      query: ({ timePeriod }) => ({
        url: `${URLS.usersRegCount}${timePeriod ? `?date_period=${timePeriod}` : ''}`,
        method: 'get',
      }),
    }),
    getEfficiency: build.query<
      EfficiencyResponseType,
      { selected: ISelectOption; currency: string }
    >({
      query: ({ selected, currency }) => ({
        url: `${URLS.efficiency}/${selected.value}?currency=${currency}`,
        method: 'get',
      }),
    }),
    getWallets: build.query<WalletsResponseType, unknown>({
      query: () => ({
        url: URLS.balances,
        method: 'get',
      }),
    }),
  }),
});

export const {
  useGetScalarsQuery,
  useGetRoyaltiesQuery,
  useGetTimeseriesQuery,
  useGetUserRegQuery,
  useGetUserRegCountQuery,
  useGetEfficiencyQuery,
  useGetWalletsQuery,
} = chartsAPI;
